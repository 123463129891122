.form_wrap {
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 20px;
  min-height: 100dvh;
  background-color: rgba(255, 255, 255, 0.364);

  .form-container {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    background-color: black;
    padding: 50px 30px;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;

    .form-container_head {
      margin-bottom: 20px;
      h2 {
        color: red;
      }
      p {
        color: white;
      }
    }
    form {
      display: flex;
      flex-direction: column;

      .form_input {
        display: flex;
        flex-direction: column;
      }

      label {
        color: white;
        padding-bottom: 7px;
        font-size: 14px;
      }

      input {
        margin-bottom: 20px;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 14px;
        border: none;
        outline: none;
        background-color: rgb(236, 236, 236);
      }
      button {
        background-color: red;
        margin-top: 20px;
        padding: 5px 20px;
        border-radius: 5px;
         color: white;
           transition:.2s ease-in-out;
           text-decoration: none;
      }
      button:hover {
        background-color: rgb(255, 87, 87);
        color: white;
        transition:.2s ease-in-out;
         text-decoration: none;
         

      }
      .check {
        display: flex;
        gap: 20px;
        .check_small {
          font-size: 14px;
          line-height: 1.2 !important;
          color: white !important;
        }
      }
      
    }
    small{
      font-size: 14px;
    }
  }
}
