.promotion {
  width: 100%;
  height: 80vh;
  align-items: center;
  justify-content: center;
  display: flex;
  .prom_con {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: auto;
    .pro-content {
      align-items: center;
      justify-content: center;
      width: 100%;
      height: auto;
      display: flex;

      .pro_fom_con {
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 40vh;
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;

        form {
          padding: 50px 80px !important;
          border-radius: 20px;
          padding: 20px;
          background-color: #50bbb977;
          backdrop-filter: blur(5px);
          flex-direction: column;
          display: flex;
          h1 {
            line-height: 0.8 !important;
            padding-bottom: 10px;
          }
          input {
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 100%;
            padding: 0;
            border: none;
            outline: none;
            box-sizing: border-box;
            background: #ecf0f3;
            padding: 10px;
            margin-bottom: 15px;
            height: 40px;
            font-size: 16px;
                border-radius: 20px;
            box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
          }
          button {
            border-radius: 30px;
            color: white;
            width: 100%;
            height: 40px;
            background-color: red;
          }
          button:hover {
            background-color: grey;
            transition: 0.3s ease-in-out;
            text-decoration: none;
            color: rgb(255, 255, 255);
          }
          span {
            font-size: 12px;
            padding-top: 10px;
            padding-left: 10px;
            cursor: pointer;
          }
          span:hover {
            color: rgb(102, 102, 102);
          }
        }
        @media screen and (max-width: 992px) {
          form {
            padding: 20px !important;
          }
        }
      }
    }
  }
}
