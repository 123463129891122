.condition {
  width: 100%;
  height: auto;
  max-width: 1400px;
  padding: 50px 100px 100px 100px;
  text-align: center;
  margin-top: 100px;
  h1 {
    font-size: 3.5rem;
    padding: 20px;
  }
  h2 {
    padding-bottom: 20px;
    color: #2B569F;
  }
}
@media screen and (max-width:1000px) {
  .condition{
 margin-top: 0px !important;
  }
  
}
