.products {
  padding: 30px 50px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 2rem;
  width: 100%;

  .left {
    flex: 1;
    height: 100%;

    h2 {
      font-size: 400;
      margin-bottom: 20px;
    }
    .inputItem {
      margin-bottom: 10px;
      select{
        // border: none !important;
        border-bottom: 1px solid lightgray;
        border-top: none;
        border-left: none;
        border-right: none;
        outline: none;
        padding-bottom: 5px;
      }
      
      label {
        margin-left: 10px;
      }
    }
    .filter-list{
      display: none;
    }
    .coming_soon{
      h5{
        font-size: 20px;
        font-weight: 600;
      }
      ul{
        text-decoration: none !important;
        padding-left: 30px;
        li{
          text-decoration: none !important;
          padding-bottom: 10px;
        }
      }
    }
  

    .filterItem {
      margin-bottom: 30px;
    }
  }
  .right {
    flex: 3;
    .free_banner{
      width: 100%;
      overflow: hidden;
      border-radius: 10px;
      img{
        width: 100%;
      }
    }

    .catImg {
      width: 100%;
      height: 300px;
      object-fit: cover;
      margin-bottom: 50px;
    }
  }
}
@media only screen and (max-width: 992px) {
  .products {
    padding: 30px;
    grid-template-columns: 1fr;
    .left { 
      .filterItem{
        display: none;

      }
      .filter-list{
        display: block;

        .filterItem_mobile{
        display: grid ;
        grid-template-columns: 1fr 1fr ;

      }

      }
      
    }
    .navbar-nav {
      width: 100%;
      padding-top: 10px;
    }
  }
}
.pro_head{
  text-align: center;
  justify-content: center;
  display: flex;
  height: 35vh;
  align-items: center;
  overflow: hidden !important;
  width: 100dvw !important;
  padding: 50px 10px !important;
  object-fit: contain !important;
  img{
    width:100%;
    height: auto;
    
  }
  h1{
    color: white;
  }
}
.product_con{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}