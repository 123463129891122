.max-width_wrap {
  max-width: 1300px;
  align-items: flex-start;
  justify-content: center;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  .product_info_section_one {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    padding-bottom: 30px;
    .text {
      margin-top: 20px;
      padding: 5px;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      strong {
        font-size: 18px;
        line-height: 1.6;
      }
      em {
        color: #411900;
        font-size: 30px;
        font-weight: 600;
      }
      h1 {
        padding-bottom: 20px;
        font-size: 30px !important;
        color: #222222;
      }
      .strong {
        margin-bottom: 10px;
      }
    }
    .img_wrap {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100% !important;
      height: auto;
      img {
        width: 100%;
      }
    }
    .btn {
      padding-top: 30px;
      button {
        border: none;
        padding: 10px 30px;
        border-radius: 30px;
        color: white;
      }
    }
    .btn_wrap {
      cursor: pointer;
      margin-top: 40px;
      padding: 20px 40px;
      width: auto;
      height: auto;
      transition: 0.2s ease-in-out;
      border: 1px solid red;
    }
    .btn_wrap:hover {
      transition: 0.2s ease-in-out;
      background-color: red;
      color: white;
    }
  }
  @media screen and (max-width: 992px) {
    .max-width_wrap {
      grid-template-columns: 1fr;
    }
  }
}
@media screen and (max-width: 992px) {
    .max-width_wrap {
      grid-template-columns: 1fr !important;
      padding: 40px;
    }
  }


//Chocolate

.chocolate {
  width: 100%;
  .max-width {
    width: 100%;

    .head_season {
      align-items: center;
      justify-content: center;
      display: flex;
      text-transform: uppercase;
      font-weight: 800 !important;
      margin-top: 100px;
      max-width: 1200px;
      border: 1px solid lightgrey;
      padding: 10px 60px;
    }
    .home_nav {
      max-width: 1200px;
      width: 100%;
      padding: 20px 20px 20px 20px;
      a {
        span {
          transition: 0.2s ease-in-out;

          color: #411900;
        }
        span:hover {
          transition: 0.2s ease-in-out;

          color: red;
        }
      }
      span {
        padding: 0px 10px;
        font-size: 14px;
      }
    }
    .product_list {
      padding-top: 40px;
      width: 100%;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
a {
  color: #411900 !important;
}
