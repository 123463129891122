.img_wrp{
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    display: flex;
    min-height: 80dvh;
    img{
        width: 600px;
    }
}