.about {
  width: 100%;
  height: auto;
  margin-top:100px !important;

  .about_banner {
    width: 100%;
    overflow: hidden;
    height: 40vh;
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 992px) {
    .about_banner {
      height: auto;
      width: 100%;
    }
  }
 
    .about_top{
      width: 100%;
      padding: 60px 60px 10px 60px;
      display: flex;
      flex-direction: column;
      align-items: center !important;
      justify-content: center !important;
      text-align: center !important;
      h1{
        padding-bottom: 20px;
      }
    }

  .profile_info {
    
   width: 100% !important;
    @media screen and (max-width: 992px) {
      .pro_head {
        padding: 30px;
      }
    }

    .pillars {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 100px;
      h2 {
        padding-bottom: 20px;
        font-size: 30px;
        color: #2b569f;
      }

      .pils_wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 40px;
        padding: 50px;

        h2 {
          padding-bottom: 20px;
        }
        .pil_item {
          border-radius: 30px;
          background-color: #f8f7fa;
          width: 100%;
          height: auto;
          text-align: center;
          justify-content: center;
          padding: 30px;
        }
      }
      @media screen and (max-width: 992px) {
        .pils_wrap {
          grid-template-columns: 1fr;
          width: 100%;
          padding: 30px !important;
        }
      }
      p {
        text-align: center;
      }
    }
    @media screen and (max-width: 992px) {
      .pillars {
        padding: 30px;
        text-align: center;
      }
    }
  }
  .leader_ship {
    padding: 100px 60px;
    background-color: #f8f7fa;
    width: 100;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      padding-bottom: 20px;
    }
    .lead_sec {
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 4rem;
      .item {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .item_pic {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: auto;
          overflow: hidden;
          padding: 20px;
          h3{
            font-size: 15px!important;
          }
          img {
            border-radius: 50%;
            width: 150px;
            height: auto;
          }
        }
        p {
          text-align: center;
        }
      }
    }
    @media screen and (max-width: 992px) {
      .lead_sec {
        grid-template-columns: 1fr;
        width: 100%;
        grid-gap: 2rem;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .leader_ship {
      padding: 50px;
      text-align: center;
    }
  }
  .values {
    padding: 100px;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    h1 {
      padding-bottom: 40px;
      color: #2b569f;
    }
    .all_items {
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 50px;

      .items {
        padding: 30px;
        width: 100%;
        height: auto;
        align-items: center;
        justify-content: center;
        text-align: center;
        h2 {
          padding-bottom: 30px;
        }
      }
    }
    @media screen and (max-width: 992px) {
      .all_items {
        grid-template-columns: 1fr;
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .values {
      padding: 30px;
      .all_items {
        grid-gap: 5px;
      }
    }
  }
}
@media screen and (max-width:992px) {
  .about{
 margin-top:0px !important;
 h1{
  font-size: 30px !important;
 }
  }
  
}