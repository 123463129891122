@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
body {

  width: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  /* margin-top: 85px !important; */
 color: #222222;
  font-smooth: inherit;
  font-smooth: initial;
  font-smooth: revert;
  font-smooth: revert-layer;
  font-smooth: unset;
  margin: 0;

  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;

}
@media screen and (max-width: 992px) {
  body {
    margin-top: 0px !important;
  }
}
/* @font-face {
  font-family: "CustomFont";
  src: url("../src/fonts/SaolDisplay-Regular.ttf");
  src: url("../src/fonts/saoldisplay-regular-webfont.woff") format("woff");
  src: url("../src/fonts/saoldisplay-regular-webfont.woff2") format("woff2");
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}
ul{
  text-align: left;
          margin: 0 !important;
          padding: 0 !important;
}
p {

  line-height: 1.6;
  

}
.colorfull{
  width: 100dvw;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
 
}
strong{
  font-size: 18px !important;
  line-height: 1.6;
}

h5{
  font-weight: "Roboto",sans-serif !important;

}
h2{
  font-size: 20px !important;
  font-weight: 800 !important;
}
h3{
  font-size: 18px !important;
  font-weight: 800 !important;

}
li{
  text-decoration: none !important;
  list-style: none;
}
ul{
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
small {
  color: #411900 ;
  font-weight: normal!important;
  line-height: 1.3 !important;

  margin: 0;
  /* font-family: "Italiana" !important; */
}
span1 {
  /* font-family: "Italiana" !important; */
  color: red;
}
h1 {
  font-size: 2rem;
}
small {
  font-size: 18px;

}
a {
  text-decoration: none !important;
color: lightslategray;
  font-weight: 500;
  font-size: 14px;
}
strong {
  font-weight: 300 !important;
  font-size: 25px;
  line-height: 1.3;
  /* color: #8f4634; */
}
.__wrap{
  max-width: 1400px;
  padding: 0px 20px;

}
.max-width {
  width: 1300px;
  height: auto;
  flex-direction: column;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  /* background-color: white !important; */
}
@media only screen and (max-width: 992px) {
  h1 {
    font-size: 2.5rem !important;
    line-height: 1 !important;
  }
  .max-width {
    width: 100%;
    max-width: none !important;
  }
}

@media only screen and (max-width: 992px) {
 body{
  margin-top: 80px !important;
 }
}

.default-nav{
    background-color: none !important;
}
iframe#webpack-dev-server-client-overlay{display:none!important}