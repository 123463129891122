.contact {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
  padding: 60px 0px;
  margin-top: 100px;

  .contact_info_wrap {
    padding: 60px;
    width: 100%;
    height: auto;
    .con_data {
      padding-bottom: 20px;
    }

    h1 {
      font-size: 3rem;
      padding-bottom: 20px;
    }
    .contact_info {
      padding: 10px 30px;
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns: 1fr 3fr;
    }
  }
  .contact_form {
    padding: 10px;
    width: 100%;
    height: auto;
    background-color: #f8f7fa;
    input {
      margin: 10px 0px;
      // width: 100%;
      padding: 10px;
      border-radius: 30px;
      border: 1px solid #f0efef;
    }
    .form_in {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
    }
    textarea {
      font-family: Helvetica, sans-serif;

      width: 100%;
      height: 100px;
      padding: 10px 10px;
      border-radius: 20px;
      border: 1px solid #f0efef;
    }
    button {
      background-color: #59c9e8;
      border: none;
      padding: 10px 30px;
      border-radius: 30px;
      color: white;
    }
  }
}
@media only screen and (max-width: (992px)) {
  .contact {
    margin-top: 0px !important;
    grid-template-columns: 1fr;
    padding: 10px !important;
    .contact_info_wrap {
      padding: 20px;
      .contact_info {
        padding: 20px !important;
        .con_data {
          padding: 20px;
        }
        .con_icon {
          padding: 5px !important;
        }
      }
    }
    .contact_form {
      padding: 10px;
    }
  }
}
