.news_my_container {
  width: 100vw;
  align-items: center;
  justify-content: center;
  // background-color: #f0dae6af;
  background-color: #f8f8f8;
  display: flex;
  padding: 50px 0px;

  .newsletter {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .news_info {
      align-items: center;
      justify-content: center;
      height: auto;
      display: flex;
      flex-direction: column;
      h1{
        line-height: 1 !important;
      }
      
    }
    p {
      color: rgb(124, 124, 124);
      text-transform: uppercase;
      padding-bottom: 30px;
    }
    h1 {
      padding: 10px;
      font-size: 2.5rem;
      text-align: center;
      line-height: 1;
    }
    .news_form {
      width: 100%;
      height: auto;
      padding: 0px 20%;
      height: auto;
      display: grid;
      grid-template-columns: 3fr 1fr;
      justify-content: center;
      align-items: center;
      .news_input {
        width: 100%;
        height: auto;
        padding: 20px;
        input {
          display: flex;
          padding: 15px 30px !important;
          border: none;
          width: 100%;
          border-radius: 30px;
        }
      }
      .News_button {
        margin: 0px 20px;
        display: flex;
        align-items: center;
        justify-content: start;

        button {
          background-color: red;
          border: none;
          width: 100%;
          padding: 15px 100px !important;
          border-radius: 30px;
          text-transform: uppercase;
          color: white;
        }
      }
    }
    @media only screen and (max-width: 992px) {
      .news_form {
        grid-template-columns: 1fr;
      }
    }
  }
}
