.slide-container{
    width: 100% !important;
    height: auto;
    max-height:80vh;
    overflow: hidden !important;
}
@media screen and (max-width:1000px) {
    .slide-container{
        height: auto;

    }
    .featured{
        h1{
            font-size: 30px !important;
            padding-bottom: 10px;
        }
    }
    
}
.nav default-nav {
    display: none !important;
}

