.home {
  
  width: 100dvw;
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .featured {
    width: 100dvw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .head_text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      strong {
        margin-bottom: 20px;
      }
    }
    .tranding {
      width: 100%;
      height: auto;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
    .shppping {
      cursor: pointer;
      align-items: center;
      justify-content: center;
      display: flex;
      border-radius: 30px;
      width: 300px;
      height: auto;
      padding: 10px 30px;
      background-color: red;
      span {
        color: rgb(255, 255, 255);
        font-size: 18px;
        font-weight: 400;
      }
    }
    .shppping:hover {
      transition: 0.2s ease-in-out;
      background-color: rgb(255, 191, 73);
      span {
        color: black;
      }
    }
  }
  .min_with {
    padding: 60px 20px 100px 20px;
    width: 1400px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .pro_cat {
      width: 100%;
      height: auto;
      display: grid;
      grid-gap: 30px;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      .pro_cat_sec {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h2 {
          margin-top: 20px;
          // font-weight: 600 !important;
        }
        .pro_cat_item {
          background-color: gray;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          .img {
            cursor: pointer;
            width: 100%;
            height: 200px;
            img {
              transition: 0.3s ease-in-out;
              width: 100%;
              height: auto;
            }
            img:hover {
              transition: 0.3s ease-out;
              transform: scale(110%);
            }
          }
          @media screen and (max-width:1000px){
            .img{
              height: auto;
              max-height: 150px;

            }
            
          }
        }
      }
    }
    @media screen and (max-width: 1000px) {
      .pro_cat {
        width: 100%;
        grid-template-columns: 1fr 1fr;
        padding: 20px;
        .pro_cat_sec{
          height: 200px;
        }
      }
    }
  }
   @media screen and (max-width: 1000px) {
      .min_with  {
        width: 100% !important;
      }
    }
   

  .text {
    h2 {
      font-size: 35px !important;
      line-height: 1 !important;
    }
  }
  .text-about {
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 100px 200px;
    text-align: center;
    line-height: 2;
    h2 {
      color: gray;
    }
  }
  @media screen and (max-width: 1000px) {
    .text-about {
      padding: 100px 30px !important ;
    }
  }

  .promo_banner {
    display: flex;
    align-items: flex-end;
    text-align: left;
    justify-content: flex-end;
    width: 100%;
    background-image: url(../../img/r_banner.jpg);
    background-size: cover;
    height: 80dvh;
    background-position: center;
    overflow: hidden;
    object-fit: contain;
    .banner_text {
      width: 100%;
      padding: 60px;
      height: auto;

      h1 {
        color: white;
        font-size: 40px;
        font-weight: 200 !important;
        line-height: 1 !important;
        em {
          color: #411900;
          font-style: normal !important;
          font-weight: 600;
        }
      }
    }

    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 900px) {
    promo_banner {
      width: 100%;
      height: 100dvh;
      overflow: hidden;
      object-fit: contain;
      img {
        width: 100%;
      }
    }
  }

  .text_ing {
    width: 100%;
    height: auto;
    align-items: center;
    justify-items: center;
    display: flex;
    flex-direction: column;
    h1 {
      padding: 40px;
    }
    .item_one {
      align-items: center;
      justify-content: center;
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
      flex-direction: column;
      width: 100%;
      .item_one_sec {
        width: 100%;
        height: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        img {
          width: 200px;
          padding-bottom: 30px;
        }
      }

      .text_con {
        text-align: center;
        width: 100%;
        height: auto;
        padding: 20px;
      }
    }
    @media screen and (max-width: 992px) {
      .item_one {
        grid-template-columns: 0.1fr 1fr 0.1fr;
      }
    }
    .main_img {
      width: 100%;
      height: auto;
      padding: 30px;
      align-items: center;
      justify-content: center;
      display: flex;
      img {
        width: 50% !important;
      }
      @media screen and (max-width: 992px) {
        img {
          width: 100%;
        }
      }
    }
    .item_two {
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 50px;

      .item_two_in {
        padding: 20px;
        width: 100%;
        height: auto;
        display: grid;
        text-align: center;
        grid-template-columns: 1fr 2fr;
        .item_two_in_img {
          align-items: center;
          justify-content: center;
          display: flex;
          width: 100%;
          img {
            width: 200px;
          }
        }
        .item_two_in_text {
          padding: 20px;
          width: 100%;
          height: auto;
          align-items: center;
          justify-content: center;
        }
      }
    }
    @media screen and (max-width: 992px) {
      .item_two {
        grid-template-columns: 1fr;

        .item_two_in {
          grid-template-columns: 1fr;
        }
        .item_two_in_text {
          padding: 50px 80px;
        }
      }
    }
  }

  .in_banner_con {
    width: 100vw;
    align-items: center;
    justify-content: center;
    display: flex;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(192, 221, 192, 1) 26%,
      rgba(192, 221, 192, 1) 75%,
      rgba(255, 255, 255, 0) 100%
    );
    margin: 100px 0px;
    .in_banner {
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      .text_bannr {
        width: 100%;
        padding: 40px 60px;
        align-items: center;
        display: flex;
        justify-content: center;
        h2 {
          color: rgb(0, 0, 0) !important;
        }
      }
      .img_bannr {
        width: 100%;
        img {
          padding: 40px;
          width: 100%;
          height: auto;
        }
      }
    }
    @media screen and (max-width: 992px) {
      .in_banner {
        grid-template-columns: 1fr;
      }
    }
  }

  .hero_wrappe {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 990;
    object-fit: contain;
  }

  .heading {
    display: flex;
    object-fit: contain;
    top: 0;
    width: 100%;
    height: 100%;
    align-items: end;
    justify-content: center;
    padding-bottom: 20px;
    text-align: center;
    position: absolute;
    z-index: 999;

    p {
      color: white !important;
      line-height: 1;
      font-size: 12px;
    }
  }
  .heading-two {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px;
    h1 {
      font-size: 2.5 rem;
      color: rgb(0, 0, 0);
    }
  }
  @media screen and (max-width: 992px) {
    .heading {
      padding-top: 30px !important;
      padding: 20px;
    }
  }

  .hero_wrappe {
    width: 100%;
    height: auto;
    height: auto;
    min-height: auto;
    overflow: hidden;
    align-items: end;
    video {
      object-fit: contain;
      width: 100%;
      justify-content: end;
      align-items: end;
      bottom: 0 !important;
    }
  }
  @media only screen and (max-width: 992px) {
    .hero_wrappe {
      height: auto;
    }
  }

  .product_info_section_one {
    padding: 40px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
    .text {
      padding: 40px;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      strong {
        font-size: 18px;
        line-height: 1.6;
      }
      em {
        color: #411900;
        font-size: 30px;
        font-weight: 600;
      }
      h1 {
        padding-bottom: 20px;
        font-size: 1rem !important;
        color: #f19c26;
      }
      .strong {
        margin-bottom: 10px;
      }
    }
    .img_wrap {
      width: 100% !important;
      height: auto;
      img {
        width: 100%;
      }
    }
    .btn {
      padding-top: 30px;
      button {
        border: none;
        padding: 10px 30px;
        border-radius: 30px;
        color: white;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .product_info_section_one {
      grid-template-columns: 1fr;
    }
  }

  .home_mission {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 100px 100px;
    text-align: center;
    h1 {
      color: white;
      padding-bottom: 20px;
    }
    strong {
      color: white;
    }
    .card_button {
      height: auto;
      padding: 20px 0px;
      button {
        background-color: #fdb653;
        border: none;
        padding: 10px 30px;
        border-radius: 30px;
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .home_mission {
      padding: 50px 20px !important;
    }
  }
  .testimonial {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    overflow: hidden !important;
    text-align: center;
    padding: 50px 100px;
    max-width: 1400px;
    h1 {
      font-size: 3.5rem;
      color: rgb(241, 156, 38);
      padding-bottom: 30px;
    }
    .test_wrap {
      width: 100%;
      height: auto;
      overflow: hidden !important;
    }
  }
  @media only screen and (max-width: 992px) {
    .testimonial {
      padding: 0px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .home {
    align-items: center;
    justify-content: center;
    display: flex;

    .product_info_section_one {
      grid-template-columns: 1fr;
      padding: 0 !important;
      .text {
        width: 100%;
        padding: 40px;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      img {
        width: 100% !important;
        align-items: center;
        justify-content: center;
        display: flex;
      }
    }

    .home_mission {
      width: 100%;
      object-fit: contain;
      img {
        width: 100%;
      }
    }
  }
}
